
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Team',
  data() {
    return {
      team: ['milli', 'bambi', 'eri', 'strix', 'haru', 'nell', 'livo', 'vash'],
    };
  },
});
